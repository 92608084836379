import { useEffect, useState } from 'react';
import { sendLog } from 'mypages-library';

type Config = {
  apiUrl: string | undefined;
  reCaptchaSiteKey: string | undefined;
};

export const useConfig = () => {
  const [config, setConfig] = useState<Config>({ apiUrl: undefined, reCaptchaSiteKey: undefined });
  useEffect(() => {
    const fetchConfig = async () => {
      if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
        setConfig({
          apiUrl: process.env.REACT_APP_ADOBE_URL,
          reCaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        });
        return;
      }
      try {
        const configResponse = await fetch('/login/config');
        const configData = await configResponse.json();
        setConfig(configData);
      } catch (e) {
        sendLog('fetch config failure', e);
      }
    };

    fetchConfig();
  }, []);

  return { config };
};
