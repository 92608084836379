import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PollManager } from 'mypages-library';
import { fetchWithFailover } from 'default-client/DefaultClient';
import { getErrorMessage } from 'common/errors/errorUtils';
import { Country, findCountryDialCodeMatch, removePhoneNumberCountryCode } from 'utils/countries';

export type CreateAccountForm = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  alternativePhone?: string;
  locale: string;
  invitationId?: string;
  recaptchaResponse?: string | null;
};

type WebAccountError = {
  title: string;
  status: number;
  type: string;
};

const useCreateAccount = (
  setEmail: (email: string) => void,
  setPhoneNumber: (phoneNumber: string) => void,
  setError: (errorMsg: string) => void,
  setSelectedCountryPhone: (country: Country) => void
) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationId = queryParams.get('invite');
  const isFromInvitation = Boolean(invitationId);

  const [invalidInvite, setInvalidInvite] = useState<boolean>(false);
  const [webAccountCreated, setWebAccountCreated] = useState<boolean>(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [isLoadingWebaccountInfo, setIsLoadingWebaccountInfo] = useState<boolean>(isFromInvitation);

  const pollerKey = 'webAccountCompleted';

  useEffect(() => {
    if (invitationId) {
      getInviteDetails();
    }
  }, [invitationId]);

  const isValidResponse = async (response: Response | undefined) => {
    if (response && !response.ok) {
      const errorData: WebAccountError = await response.json();

      handleError(errorData.type);
      return false;
    }
    return true;
  };

  const handleError = (error: string) => {
    // If it is an invalid invite, a different component will be displayed not just an error message
    if (error === 'urn:verisure:webaccount/no-such-invitation') {
      setInvalidInvite(true);
      return;
    }

    const errorMsg = getErrorMessage(error);
    setError(errorMsg);
  };

  const getInviteDetails = async () => {
    try {
      const inviteDetails = await fetchWithFailover(`/webaccount/public/invitation/${invitationId}`, {
        headers: {
          APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
        },
      });

      const valid = await isValidResponse(inviteDetails);

      if (valid && inviteDetails) {
        const { receiverEmail, receiverPhoneNumber } = await inviteDetails.json();
        const country = findCountryDialCodeMatch(receiverPhoneNumber);
        const phoneNumber = removePhoneNumberCountryCode(receiverPhoneNumber, country);
        setEmail(receiverEmail);
        setPhoneNumber(phoneNumber);
        if (country) {
          setSelectedCountryPhone(country);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        handleError(e.message);
      } else {
        handleError('Unknown error');
      }
    } finally {
      setIsLoadingWebaccountInfo(false);
    }
  };

  const pollWebAccountCompleted = async (email: string) =>
    PollManager.poll(
      async () => {
        const response = await fetchWithFailover(`/webaccount/public/${email}/completed`, {
          headers: {
            APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
          },
        });

        const valid = await isValidResponse(response);
        if (valid && response) {
          const { completed } = await response.json();
          return completed;
        }
      },
      { key: pollerKey, timeout: 15000 }
    );

  const createAccount = async (createAccountForm: CreateAccountForm) => {
    try {
      setIsCreatingAccount(true);
      const { email, ...accountInformation } = createAccountForm;
      const accountCreatedResponse = await fetchWithFailover(`/webaccount/public/${email}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
        },
        body: JSON.stringify(accountInformation),
      });

      const valid = await isValidResponse(accountCreatedResponse);

      if (valid) {
        const isWebAccountCreated = await pollWebAccountCompleted(email);
        setWebAccountCreated(isWebAccountCreated);
      }
    } catch (e) {
      if (e instanceof Error) {
        handleError(e.message);
      } else {
        handleError('Unknown error');
      }
    } finally {
      setIsCreatingAccount(false);
    }
  };

  const submitCreateAccount = async (createAccountForm: CreateAccountForm) => {
    await (invitationId ? createAccount({ ...createAccountForm, invitationId }) : createAccount(createAccountForm));
  };

  return {
    isFromInvitation,
    submitCreateAccount,
    webAccountCreated,
    isCreatingAccount,
    invalidInvite,
    isLoadingWebaccountInfo,
  };
};

export default useCreateAccount;
