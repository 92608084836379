import { LoadingSpinner } from 'mypages-library';
import React from 'react';
import WebAccountVerified from 'components/WebAccountVerified/WebAccountVerified';
import useVerifyAccount, { VerifyAccountStatus } from 'components/VerifyAccount/useVerifyAccount';
import WebAccountVerificationFailed from 'components/WebAccountVerifictionFailed/WebAccountVerificationFailed';
import useGetDealerSettings from 'common/hooks/useGetDealerSettings';

interface VerifyAccountProps {}

const VerifyAccount: React.FC<VerifyAccountProps> = () => {
  const { status } = useVerifyAccount();
  const {
    dealerSettings: { phone },
  } = useGetDealerSettings();

  switch (status) {
    case VerifyAccountStatus.Completed:
      return <WebAccountVerified />;
    case VerifyAccountStatus.Error:
    case VerifyAccountStatus.Invalid:
    case VerifyAccountStatus.UndefinedSecret:
      return <WebAccountVerificationFailed supportPhoneNumber={phone} />;
    case VerifyAccountStatus.InProgress:
    default:
      return <LoadingSpinner isLoading centered size="large" dataTestId="spinner" />;
  }
};

export default VerifyAccount;
