/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import { t } from 'i18n/translate';

type AgreementData = {
  _path: string;
  content: { html: string };
};

const useGetTermsAndConditions = (apiUrl: string | undefined) => {
  const [terms, setTerms] = useState<AgreementData[]>([]);
  const [error, setError] = useState<string>('');

  const formattedLocale = (locale: string) => {
    if (locale === 'no_NO') {
      return 'no/nb';
    }

    return `${locale.slice(3).toLowerCase()}/${locale.slice(0, 2)}`;
  };

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const fetchedTerms = await fetch(`${apiUrl}/graphql/execute.json/verisure/webaccountAgreements`);
        const {
          data: {
            agreementList: { items },
          },
        } = await fetchedTerms.json();
        setError('');
        setTerms(items);
      } catch (e) {
        setError(t('MPL_ETC_ERROR_OCCURED_TRY_AGAIN'));
      }
    };
    if (apiUrl) {
      fetchTerms();
    } else {
      setError(t('MPL_ETC_ERROR_OCCURED_TRY_AGAIN'));
    }
  }, [apiUrl]);

  const getAgreementByLocale = (locale: string) =>
    terms.find((agreement: AgreementData) => agreement._path.includes(formattedLocale(locale)))?.content?.html ?? null;

  return { getAgreementByLocale, error };
};

export default useGetTermsAndConditions;
