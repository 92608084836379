import React from 'react';
import { Button } from 'mypages-library';
import { useHistory } from 'react-router-dom';
import { t } from 'i18n/translate';
import GenericWebAccountStatus from 'components/WebAccountCreated/GenericWebAccountStatus';

export type WebAccountVerificationFailedProps = {
  supportPhoneNumber: string;
};

const WebAccountVerificationFailed: React.FC<WebAccountVerificationFailedProps> = ({
  supportPhoneNumber,
}: WebAccountVerificationFailedProps) => {
  const navigate = useHistory();
  const goToLogin = () => {
    navigate.push('/login');
  };

  return (
    <GenericWebAccountStatus
      header={t('MPL_COMMON_EMAIL_VERIFICATION_FAILED_TITLE')}
      body={t('MPL_COMMON_EMAIL_VERIFICATION_FAILED_DESCRIPTION', { phone: supportPhoneNumber })}
      dataTestId="web-account-verification-failed"
    >
      <Button primary text={t('MPL_CREATE_ACCOUNT_VERIFY_LOG_IN')} onClick={goToLogin} />
    </GenericWebAccountStatus>
  );
};

export default WebAccountVerificationFailed;
