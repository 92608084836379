import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Checkbox, Input } from 'mypages-library';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import { t } from 'i18n/translate';
import PasswordInput from 'components/Input/PasswordInput';
import InputValidation from 'components/Input/InputValidation';
import { getDealerSettings } from 'components/CreateAccount/TermsAndConditions/privacyUtils';
import useGetTermsAndConditions from 'components/CreateAccount/TermsAndConditions/useGetTermsAndConditions';
import { getDefaultCountry } from 'utils/countries';
import TermsAndCondition from 'components/CreateAccount/TermsAndConditions/TermsAndCondition';
import { useConfig } from 'common/hooks/useConfig';
import CookieInfo from 'components/common/CookieInfo/CookieInfo';
import {
  isPasswordValid,
  passwordAndRepeatMatch,
  getPasswordRequirements,
  getCurrentPasswordErrors,
} from '../Input/passwordValidationUtil';
import styles from './VerifyAccountSetPassword.module.scss';

const VerifyAccountSetPassword: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const secret = searchParams.get('secret');

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const passwordError = password?.length > 0 && !isPasswordValid(password);
  const repeatPasswordError = repeatPassword?.length > 0 && !passwordAndRepeatMatch(password, repeatPassword);
  const repeatPasswordErrorMsg = repeatPasswordError ? t('MPL_PASSWORD_VALIDATION_PASSWORDS_NOT_MATCHING') : '';
  const [locale] = useState<string>(getDefaultCountry(language));
  const [agree, setAgree] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [disabledByError, setDisabledByError] = useState<boolean>(false);

  const openPrivacyPolicy = async () => {
    const { privacyPolicy, errorText } = await getDealerSettings(locale);
    if (errorText) {
      setErrorMsg(errorText);
    } else {
      window.open(privacyPolicy, '_blank');
    }
  };
  const [showTerms, setShowTerms] = useState(false);
  const {
    config: { apiUrl },
  } = useConfig();
  const { getAgreementByLocale, error: agreementError } = useGetTermsAndConditions(apiUrl);
  useEffect(() => {
    if (disabledByError) {
      setDisabledByError(false);
    }
  }, [password, repeatPassword, agree, locale]);

  const validForm =
    password?.length > 0 && !passwordError && repeatPassword?.length > 0 && !repeatPasswordError && agree;
  const disableSubmit = !validForm || disabledByError; // not yet implemented hook || isVerifingAccount ;

  const handleSubmit = async () => null;

  if (showTerms) {
    return (
      <div className={styles['terms-wrapper']}>
        <TermsAndCondition terms={getAgreementByLocale(locale)} error={agreementError} setShowTerms={setShowTerms} />
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <main className={styles.content}>
        <SubsidiaryAppLogo />
        <div className={styles.inputContainer}>
          <Input
            className={styles.disabledInput}
            onChange={() => null}
            placeholder={t('MPL_LOGIN_PLACEHOLDER_INSTALLATION_LAND')}
            value="Italy"
            dataTestId="land"
            disabled
          />
          <InputValidation
            inputToValidate={password}
            requirements={getPasswordRequirements(password)}
            errorList={getCurrentPasswordErrors(password)}
          >
            <PasswordInput
              placeholder={t('MPL_LOGIN_PLACEHOLDER_PASSWORD')}
              onChange={(value: string) => setPassword(value)}
              value={password}
              maxLength={30}
              className={styles['login-field']}
              dataTestId="verify-password"
              autoComplete="current-password"
              hasError={passwordError}
            />
          </InputValidation>
          <PasswordInput
            placeholder={t('MPL_LOGIN_PLACEHOLDER_REPEAT_PASSWORD')}
            onChange={(value: string) => setRepeatPassword(value)}
            value={repeatPassword}
            maxLength={30}
            className={styles['login-field']}
            dataTestId="verify-password-repeat"
            autoComplete="current-password"
            hasError={repeatPasswordError}
            errorMessage={repeatPasswordErrorMsg}
          />
          <div className={styles.agreement}>
            <Checkbox dataTestId="placement-toggle-agreement" isChecked={agree} onToggle={() => setAgree(!agree)} />
            <p className={styles.agreementText}>
              {t('MPL_TERMS_AND_CONDITIONS_AGREEMENT_INFO')}
              <button
                className={classNames(styles.linkText, styles.agreementLink)}
                type="button"
                onClick={() => setShowTerms(!showTerms)}
              >
                {t(`MPL_TERMS_AND_CONDITIONS`)}
              </button>
            </p>
          </div>
          <p className={styles.privacyText}>
            {t('MPL_PRIVACY_POLICY_INFORMATION')}
            <button
              type="button"
              onClick={openPrivacyPolicy}
              className={classNames(styles.linkText, styles.agreementLink)}
            >
              {t('MPL_PRIVACY_POLICY')}
            </button>
          </p>
        </div>
        {errorMsg && <p className={styles.error}>{errorMsg}</p>}
        <Button
          text={t('MPL_SAVE')}
          className={styles['login-button']}
          red
          onClick={handleSubmit}
          dataTestId="verify-submit"
          disabled={disableSubmit}
        />
        <Button className={styles.cancelButton} text={t('MPL_CANCEL')} link onClick={() => navigate.push('/login')} />
      </main>
      <CookieInfo />
    </div>
  );
};

export default VerifyAccountSetPassword;
