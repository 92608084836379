import { closeWebView } from 'mypages-library';
import { useLocation, useHistory } from 'react-router-dom';

const useNavigateOrClose = () => {
  const navigate = useHistory();
  const location = useLocation();

  const navigateOrClose = (path: string) => {
    const searchParams = new URLSearchParams(location.search);
    const runningInApp = searchParams.get('mm_app');
    if (runningInApp) {
      return closeWebView();
    }
    navigate.push(path);
  };

  return navigateOrClose;
};
export default useNavigateOrClose;
