import React from 'react';

import verified from 'images/wbaccount/verification.png';
import { useInitialize } from 'common/hooks/useInitialize';
import AppStore from 'components/AppStore/AppStore';
import { t } from 'i18n/translate';
import GenericWebAccountStatus from 'components/WebAccountCreated/GenericWebAccountStatus';
import styles from './WebAccountVerified.module.scss';

const WebAccountVerified: React.FC = () => {
  const { inapp } = useInitialize();
  const runningInApp = sessionStorage.getItem('runningInApp') === 'true' || inapp;
  return (
    <GenericWebAccountStatus
      header={t('MPL_CREATE_ACCOUNT_VERIFY_TITLE')}
      body={t('MPL_CREATE_ACCOUNT_VERIFY_DESC')}
      image={verified}
      altText="Web account verified"
      dataTestId="web-account-verified"
    >
      {!runningInApp && (
        <div className={styles.appStoreContainer}>
          <AppStore />
        </div>
      )}

      <a href="/login" className={styles.loginBtn}>
        {t('MPL_CREATE_ACCOUNT_VERIFY_LOG_IN')}
      </a>
    </GenericWebAccountStatus>
  );
};

export default WebAccountVerified;
