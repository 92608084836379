import React from 'react';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import CookieInfo from 'components/common/CookieInfo/CookieInfo';
import styles from './WebAccountCreated.module.scss';

type GenericWebAccountStatusProps = {
  header: string;
  body: string;
  image?: string;
  children?: React.ReactNode;
  altText?: string;
  dataTestId?: string;
};

const GenericWebAccountStatus = ({
  header,
  body,
  image,
  children,
  altText,
  dataTestId,
}: GenericWebAccountStatusProps) => (
  <div id={dataTestId || undefined} className={styles.centered}>
    <div className={styles.container}>
      <SubsidiaryAppLogo />
      <div className={styles.infoContainer}>
        <h1 className={styles.infoHeader}>{header}</h1>
        <p className={styles.infoText}>{body}</p>
      </div>
      {image && (
        <div className={styles.imageContainer}>
          <img src={image} className={styles.webAccountImage} alt={altText} />
        </div>
      )}
      {children}
    </div>
    <CookieInfo />
  </div>
);

GenericWebAccountStatus.defaultProps = {
  children: null,
  altText: null,
  image: null,
  dataTestId: null,
};

export default GenericWebAccountStatus;
