/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from 'mypages-library';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import { getDefaultCountry } from 'utils/countries';
import styles from './CookiePolicy.module.scss';

type CookiePolicyContent = {
  title: string;
  content: { html: string };
};

const CookiePolicy = () => {
  const [cookiePolicy, setCookiePolicies] = useState<CookiePolicyContent | null>(null);
  const {
    i18n: { language },
  } = useTranslation();
  const locale = getDefaultCountry(language);

  const fetchCookiePolicy = async () => {
    const urlData = await fetch('/login/config');
    const { apiUrl } = await urlData.json();
    const localeForAdobe = locale === 'no_NO' ? 'nb_NO' : locale;
    const fetchedCookiePolicy = await fetch(`${apiUrl}/graphql/execute.json/verisure/cookiepolicy-${localeForAdobe}`);
    const {
      data: {
        informationList: { items },
      },
    } = await fetchedCookiePolicy.json();
    setCookiePolicies(items[0]);
  };

  useEffect(() => {
    fetchCookiePolicy();
  }, []);

  if (!cookiePolicy) {
    return <LoadingSpinner centered isLoading={!cookiePolicy} />;
  }

  return (
    <div className={styles.contentWrapper}>
      <SubsidiaryAppLogo />
      <main>
        <p className={styles.cookiePolicyTitle}>{cookiePolicy?.title}</p>
        <div
          className={styles.cookiePolicyWrapper}
          dangerouslySetInnerHTML={{
            __html: cookiePolicy?.content.html,
          }}
        />
      </main>
    </div>
  );
};

export default CookiePolicy;
