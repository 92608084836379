import React, { useState } from 'react';
import { Icon } from 'mypages-library';
import { t } from 'i18n/translate';

import styles from './CookieInfo.module.scss';

function CookieInfo() {
  const cookieBannerVisible = localStorage.getItem('cookie-banner-state') !== 'cleared';
  const [cookieInfoVisible, setCookieInfoVisible] = useState(cookieBannerVisible);

  if (!cookieInfoVisible) {
    return null;
  }

  const closeCookieBanner = () => {
    setCookieInfoVisible(false);
    localStorage.setItem('cookie-banner-state', 'cleared');
  };
  return (
    <section className={styles['cookie-banner']}>
      <div className={styles['cookie-banner-content']}>
        <p>{t('MPL_COOKIE_BANNER_TITLE')}</p>
        <p>
          {t('MPL_COOKIE_BANNER_READ_MORE')}&nbsp;
          <a
            href="/login/cookiepolicy"
            target="_blank"
            rel="noopener noreferrer"
            className={styles['cookie-info-link']}
          >
            {t('MPL_COOKIE_BANNER_USE_OF_COOKIES_LINK')}
          </a>
        </p>
      </div>
      <Icon icon="icon-close" className={styles['cookie-banner-close-icon']} onClick={closeCookieBanner} />
    </section>
  );
}

export default CookieInfo;
