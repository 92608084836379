import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Checkbox, LoadingSpinner, appConstants, validation } from 'mypages-library';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/Input/Input';
import { t } from 'i18n/translate';
import SubsidiaryAppLogo from 'components/SubsidiaryAppLogo/SubsidiaryAppLogo';
import InternationalPhonePicker from 'components/InternationalPhonePicker/InternationalPhonePicker';
import PasswordInput from 'components/Input/PasswordInput';
import WebAccountCreatedFromInvitation from 'components/WebAccountCreated/WebAccountFromInvitation';
import CountryPicker from 'components/CreateAccount/CountrySelector/CountrySelector';
import WebAccountInvalidInvite from 'components/WebAccountInvalidInvite/WebAccountInvalidInvite';
import WebAccountCreated from 'components/WebAccountCreated/WebAccountCreated';
import { findPreselectedCountry, getDefaultCountry, formatPhoneForApi, Country } from 'utils/countries';
import { ReCaptcha } from 'components/common/ReCaptcha/ReCaptcha';
import LanguageMenu from 'components/LanguageMenu/LanguageMenu';
import useNavigateOrClose from 'common/hooks/useNavigateOrClose';
import { refreshSessionStorage } from 'common/hooks/useInitialize';
import { useConfig } from 'common/hooks/useConfig';
import CookieInfo from 'components/common/CookieInfo/CookieInfo';
import InputValidation from '../Input/InputValidation';
import {
  isPasswordValid,
  passwordAndRepeatMatch,
  getPasswordRequirements,
  getCurrentPasswordErrors,
} from '../Input/passwordValidationUtil';
import TermsAndCondition from './TermsAndConditions/TermsAndCondition';
import useGetTermsAndConditions from './TermsAndConditions/useGetTermsAndConditions';
import styles from './CreateAccount.module.scss';
import { getDealerSettings } from './TermsAndConditions/privacyUtils';
import useCreateAccount, { CreateAccountForm } from './useCreateAccount';

export type LocaleToCountryStringMappingEntries = [
  keyof typeof localeToCountryStringMapping,
  typeof localeToCountryStringMapping[keyof typeof localeToCountryStringMapping]
];

export const localeToCountryStringMapping = {
  de_DE: 'Deutschland',
  da_DK: 'Danmark',
  fi_FI: 'Suomi',
  fr_FR: 'France',
  fr_BE: 'Belgique',
  nl_NL: 'Nederland',
  nl_BE: 'België',
  no_NO: 'Norge',
  sv_SE: 'Sverige',
  en_GB: 'United Kingdom',
} as const;

const CreateAccount: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  refreshSessionStorage();
  const navigateOrClose = useNavigateOrClose();

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [agree, setAgree] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);
  const [selectedCountryPhone, setSelectedCountryPhone] = useState<Country>(findPreselectedCountry(language));
  const [locale, setLocale] = useState<string>(getDefaultCountry(language));
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [disabledByError, setDisabledByError] = useState<boolean>(false);

  const handleError = (errorMessage: string) => {
    setDisabledByError(true);
    setErrorMsg(errorMessage);
  };

  const {
    isFromInvitation,
    submitCreateAccount,
    webAccountCreated,
    invalidInvite,
    isCreatingAccount,
    isLoadingWebaccountInfo,
  } = useCreateAccount(setEmail, setPhoneNumber, handleError, setSelectedCountryPhone);

  useEffect(() => {
    if (disabledByError) {
      setDisabledByError(false);
    }
  }, [firstName, lastName, phoneNumber, email, password, repeatPassword, agree, locale]);

  const { isNameValid, isPhoneNumberValid, isEmailValid } = validation;
  const { NAME_MAX_LENGTH, NAME_MIN_LENGTH } = appConstants;
  const firstNameError = firstName?.length > 0 && !isNameValid(firstName);
  const firstNameErrorMsg = firstNameError ? t('MPL_CREATE_ACCOUNT_NAME_VALIDATION_ERROR') : '';
  const lastNameError = lastName?.length > 0 && !isNameValid(lastName);
  const lastNameErrorMsg = lastNameError ? t('MPL_CREATE_ACCOUNT_NAME_VALIDATION_ERROR') : '';
  const phoneError = phoneNumber?.length > 0 && !isPhoneNumberValid(phoneNumber);
  const emailError = email?.length > 0 && !isEmailValid(email);
  const emailErrorMsg = emailError ? t('MPL_CREATE_ACCOUNT_INVALID_EMAIL') : '';
  const passwordError = password?.length > 0 && !isPasswordValid(password);
  const repeatPasswordError = repeatPassword?.length > 0 && !passwordAndRepeatMatch(password, repeatPassword);
  const repeatPasswordErrorMsg = repeatPasswordError ? t('MPL_PASSWORD_VALIDATION_PASSWORDS_NOT_MATCHING') : '';

  const openPrivacyPolicy = async () => {
    const { privacyPolicy, errorText } = await getDealerSettings(locale);
    if (errorText) {
      setErrorMsg(errorText);
    } else {
      window.open(privacyPolicy, '_blank');
    }
  };
  const [showTerms, setShowTerms] = useState(false);
  const {
    config: { reCaptchaSiteKey, apiUrl },
  } = useConfig();
  const { getAgreementByLocale, error: agreementError } = useGetTermsAndConditions(apiUrl);

  const getFormData = (): CreateAccountForm => ({
    firstName,
    lastName,
    mobilePhone: formatPhoneForApi(phoneNumber, selectedCountryPhone),
    email,
    password,
    locale,
    recaptchaResponse,
  });

  const validForm =
    firstName?.length > 0 &&
    !firstNameError &&
    lastName?.length > 0 &&
    !lastNameError &&
    phoneNumber?.length > 0 &&
    !phoneError &&
    email?.length > 0 &&
    !emailError &&
    password?.length > 0 &&
    !passwordError &&
    repeatPassword?.length > 0 &&
    !repeatPasswordError &&
    agree &&
    (isFromInvitation ? true : recaptchaResponse);

  const disableSubmit = !validForm || isCreatingAccount || disabledByError;

  const handleSubmit = async () => {
    setErrorMsg(null);
    setLoading(true);
    await submitCreateAccount(getFormData());
    setLoading(false);
  };

  const onSelectionFieldChange = (selected: string) => {
    if (agree) {
      setAgree(false);
    }
    setLocale(selected);
  };

  if (showTerms) {
    return (
      <div className={styles['terms-wrapper']}>
        <TermsAndCondition terms={getAgreementByLocale(locale)} error={agreementError} setShowTerms={setShowTerms} />
      </div>
    );
  }

  if (webAccountCreated) {
    return isFromInvitation ? <WebAccountCreatedFromInvitation /> : <WebAccountCreated email={email} />;
  }

  if (invalidInvite) {
    return <WebAccountInvalidInvite />;
  }

  return (
    <>
      {isLoadingWebaccountInfo ? (
        <LoadingSpinner centered isLoading={isLoadingWebaccountInfo} />
      ) : (
        <div className={styles.loginWrapper}>
          <main className={styles.loginContent}>
            <SubsidiaryAppLogo />
            <div className={styles.inputContainer}>
              <Input
                onChange={value => setFirstName(value)}
                placeholder={t('MPL_CREATE_ACCOUNT_FIRSTNAME')}
                value={firstName}
                dataTestId="firstNameInput"
                disabled={loading}
                maxLength={NAME_MAX_LENGTH}
                minLength={NAME_MIN_LENGTH}
                hasError={firstNameError}
                errorMessage={firstNameErrorMsg}
                focus
              />
              <Input
                onChange={value => setLastName(value)}
                placeholder={t('MPL_CREATE_ACCOUNT_LASTNAME')}
                value={lastName}
                dataTestId="lastNameInput"
                disabled={loading}
                maxLength={NAME_MAX_LENGTH}
                minLength={NAME_MIN_LENGTH}
                hasError={lastNameError}
                errorMessage={lastNameErrorMsg}
              />
              <InternationalPhonePicker
                setSelectedCountryPhone={setSelectedCountryPhone}
                selectedCountryPhone={selectedCountryPhone}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneError={phoneError}
              />
              <Input
                type="text"
                placeholder={t('MPL_LOGIN_PLACEHOLDER_EMAIL')}
                onChange={value => {
                  setEmail(value.trim());
                }}
                value={email}
                maxLength={255}
                className={isFromInvitation ? styles.disabledInput : ''}
                dataTestId="mypages-login-email"
                autoComplete="username"
                minLengthErrorText={t('MPL_NOT_ENOUGH_CHARACTERS_ERROR')}
                mandatoryInfoText={t('MPL_ETC_MANDATORY_INPUT_FIELD')}
                hasError={emailError}
                errorMessage={emailErrorMsg}
                disabled={isFromInvitation}
              />
              <InputValidation
                inputToValidate={password}
                requirements={getPasswordRequirements(password)}
                errorList={getCurrentPasswordErrors(password)}
              >
                <PasswordInput
                  placeholder={t('MPL_LOGIN_PLACEHOLDER_PASSWORD')}
                  onChange={(value: string) => setPassword(value)}
                  value={password}
                  maxLength={30}
                  className={styles['login-field']}
                  dataTestId="mypages-login-password"
                  autoComplete="current-password"
                  hasError={passwordError}
                />
              </InputValidation>
              <PasswordInput
                placeholder={t('MPL_LOGIN_PLACEHOLDER_REPEAT_PASSWORD')}
                onChange={(value: string) => setRepeatPassword(value)}
                value={repeatPassword}
                maxLength={30}
                className={styles['login-field']}
                dataTestId="mypages-login-password-repeat"
                autoComplete="current-password"
                hasError={repeatPasswordError}
                errorMessage={repeatPasswordErrorMsg}
              />
              {!isFromInvitation && (
                <CountryPicker
                  entries={Object.entries(localeToCountryStringMapping) as LocaleToCountryStringMappingEntries[]}
                  onSelectionFieldChange={onSelectionFieldChange}
                  defaultValue={locale}
                  disabled={false}
                />
              )}

              <div className={styles.agreement}>
                <Checkbox dataTestId="placement-toggle-agreement" isChecked={agree} onToggle={() => setAgree(!agree)} />
                <p className={styles.agreementText}>
                  {t('MPL_TERMS_AND_CONDITIONS_AGREEMENT_INFO')}
                  <button
                    className={classNames(styles.linkText, styles.agreementLink)}
                    type="button"
                    onClick={() => setShowTerms(!showTerms)}
                  >
                    {t(`MPL_TERMS_AND_CONDITIONS`)}
                  </button>
                </p>
              </div>
              <p className={styles.privacyText}>
                {t('MPL_PRIVACY_POLICY_INFORMATION')}
                <button
                  type="button"
                  onClick={openPrivacyPolicy}
                  className={classNames(styles.linkText, styles.agreementLink)}
                >
                  {t('MPL_PRIVACY_POLICY')}
                </button>
              </p>
              {!isFromInvitation && <ReCaptcha onChange={setRecaptchaResponse} siteKey={reCaptchaSiteKey} />}
            </div>
            {errorMsg && <p className={styles.error}>{errorMsg}</p>}
            <Button
              text={t('MPL_SAVE')}
              className={styles['login-button']}
              red
              onClick={handleSubmit}
              dataTestId="mypages-create-submit"
              disabled={disableSubmit}
            />
            <Button
              className={styles.cancelButton}
              text={t('MPL_CANCEL')}
              link
              onClick={() => navigateOrClose('/login')}
            />
          </main>
          <footer id="footer" className={styles.footer}>
            <LanguageMenu />
          </footer>
          <CookieInfo />
        </div>
      )}
    </>
  );
};

export default CreateAccount;
